import * as React from "react";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import {Autocomplete, Avatar, Button, CircularProgress, FormLabel, Select, TextField} from "@mui/material";
import {base_url} from "../../index";
import MenuItem from "@mui/material/MenuItem";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {Link, useParams} from "react-router-dom";
import Table from "@mui/material/Table";
import Alerts from "../Alerts/Alerts";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import HistoryOfChanges from "../History/HistoryOfChanges";
import save_logs from "../../tools/save_logs";
import TokenChecker from "../Header/TokenChecker";


export default function Player() {
  const [playerData, setPlayerData] = useState(null);
  const [editedPlayerData, setEditedPlayerData] = useState(null);
  const [nationalityFound, setNationalityFound] = useState([])
  const [natQuerySearch, setNatQuerySearch] = useState('')
    const [selectedNationality, setSelectedNationality] = useState([])
    const [positionFound, setPositionFound] = useState([])
  const [posQS, setPosQS] = useState('')
    const [selectedPosition, setSelectedPosition] = useState([])
    const [image, setImage] = useState()
const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()
    const [loading, setLoading] = useState(false);
  const [usePlayerReprs, setUsePlayerReprs] = useState(false);

  let { id } = useParams();

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await fetch(base_url + 'player/' + id);
        const data = await response.json();
        setPlayerData(data);
        setEditedPlayerData(data);

      } catch (error) {
        console.error('Error fetching player data:', error);
      }
    };

    fetchPlayerData();
  }, []);

            console.log(image)


  const handleFormSubmit = async (event) => {
    event.preventDefault();
    console.log(selectedPosition)

    const endpoint = usePlayerReprs ? 'player_reprs/' : 'player_update/';
    const entity_id = usePlayerReprs ? playerData.profile && playerData.profile.length > 0 ? playerData.profile[0].id : null : id;

    try {
        setLoading(true)
        let body = {
              "name": editedPlayerData.name,
              "surname": editedPlayerData.surname,
              "middle_name": editedPlayerData.middle_name,
              "nickname": editedPlayerData.nickname,
              "birth_date": editedPlayerData.birth_date,
              "leg": editedPlayerData.leg,
              "height": editedPlayerData.height,
              "weight": editedPlayerData.weight,
              "gender": editedPlayerData.gender,
            }
            if(selectedPosition.length !== 0) {
                body["position"] = selectedPosition.map(position => position.id)
            }
            if(selectedNationality.length !== 0) {
                body["nationality"] = selectedNationality.map(nation => nation.id)
            }
      const response = await fetch(base_url + endpoint + entity_id + '/', {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            body
        ),
      });

            console.log(response)

      if (response.status === 200) {
          if(image !== undefined) {
              await addPlayerPhoto()
          }
          await setAnswer('success')
          await setMessage(response)
          await save_logs('player', id, 'editing',
              editedPlayerData.name + ' ' + editedPlayerData.surname)
      } else {
        await setAnswer('error')
          await setMessage(response)
      }
    } catch (error) {
      console.error('Error updating player data:', error);
    }
    finally {
        setLoading(false)
    }
  };

            const addPlayerPhoto = async () => {
                let formData = new FormData()
                formData.append('player', id)
                formData.append('image', image[0])

                try {
                    const response = await fetch(base_url + "player_photo/", {
                        method: "POST",
                        body: formData
                    })
                    const data = await response.json()
                    console.log(data)
                }
                catch (e) {
                    console.log(e)
                }
    }

  const handleNationalitySearchChange = async (event) => {
      const { value } = event.target;
      setNatQuerySearch(value);

      try {
        const response = await fetch(base_url + 'nationality/?search=' + value);
        const data = await response.json();
        setNationalityFound(data.results);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };

  const handlePositionSearchChange = async (event) => {
      const { value } = event.target;
      setPosQS(value);

      try {
        const response = await fetch(base_url + 'position/?search=' + value);
        const data = await response.json();
        setPositionFound(data.results);
      } catch (error) {
        console.error('Error searching nationalities:', error);
      }
    };


  const handleFieldChange = (event) => {
    const { name, value } = event.target;
    setEditedPlayerData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addItemToNationality = (id, name, flag) => {
  setSelectedNationality((prevArray) => [...prevArray, {
      "id": id,
      "name": name,
      "flag": flag
  }]);
};

  const addItemToPosition = (id, name) => {
  setSelectedPosition((prevArray) => [...prevArray, {
      "id": id,
      "name": name
  }]);
    };

  function onImageChange(e) {
        setImage([...e.target.files])
    }

    console.log(playerData)

  return (
      <div>
                      <TokenChecker />

                <Grid container spacing={2} style={{marginTop: 35}}>
                  <Grid item xs={6}>
                  {playerData && (
                    <form onSubmit={handleFormSubmit}>
                      <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        value={editedPlayerData.name}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                      <TextField
                        label="Last Name"
                        name="surname"
                        fullWidth
                        value={editedPlayerData.surname}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                      <TextField
                        label="Middle Name"
                        name="middle_name"
                        fullWidth
                        value={editedPlayerData.middle_name}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                      <TextField
                        label="Nickname"
                        name="nickname"
                        fullWidth
                        value={editedPlayerData.nickname}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                      <TextField
                        label="Date of Birth"
                        name="birth_date"
                        type="date"
                        fullWidth
                        value={editedPlayerData.birth_date}
                        onChange={handleFieldChange}
                        style={{ marginTop: 5}}
                      />

                        <TextField
                            label="Search Nationality"
                            name="nationalitySearch"
                            fullWidth
                            value={natQuerySearch}
                            onChange={handleNationalitySearchChange}
                            style={{ marginTop: 5}}
                        />

                        {editedPlayerData.nationality && (
                            <div>
                                <p>
                                    Previously added nationalities for the player:
                                </p>
                                <ul>
                                {editedPlayerData.nationality.map((nationality) => (
                                  <li key={nationality.id}>{nationality.name}</li>
                                ))}
                              </ul>
                            </div>
                        )}

                        <TextField
                            label="Search Position"
                            name="positionSearch"
                            fullWidth
                            value={posQS}
                            onChange={handlePositionSearchChange}
                            style={{ marginTop: 5}}
                        />

                        {editedPlayerData.position && (
                            <div>
                                <p>
                                    Previously added positions for the player:
                                </p>
                                <ul>
                                {editedPlayerData.position.map((pos) => (
                                  <li key={pos.id}>{pos.name}</li>
                                ))}
                              </ul>
                            </div>
                        )}

                      <FormControl fullWidth>
                        <InputLabel id="gender-label">Gender</InputLabel>
                        <Select
                          labelId="gender-label"
                          id="gender"
                          name="gender"
                          value={editedPlayerData.gender}
                          onChange={handleFieldChange}
                          style={{ marginTop: 5}}
                        >
                          <MenuItem value="M">Male</MenuItem>
                          <MenuItem value="F">Female</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl fullWidth>
                        <InputLabel id="leg-label">Leg</InputLabel>
                        <Select
                          labelId="leg-label"
                          id="leg"
                          name="leg"
                          value={editedPlayerData.leg}
                          onChange={handleFieldChange}
                          style={{ marginTop: 5}}
                        >
                          <MenuItem value="R">Right</MenuItem>
                          <MenuItem value="L">Left</MenuItem>
                          <MenuItem value="R+L">Right + Left</MenuItem>
                        </Select>
                      </FormControl>
                          <Button variant="contained" component="label">
                                                        Upload Photo of Player <input accept="image/*" type="file" onChange={onImageChange}/>
                                         </Button>

                        <p>

                        </p>
                        <input
                                  type="checkbox"
                                  checked={usePlayerReprs}
                                  onChange={(e) => setUsePlayerReprs(e.target.checked)}
                                />
                                <label>Update Last representation of Player with this data</label>
                        <p></p>
                      <Button type="submit" variant="contained" color="primary">
                        Save
                      </Button>
                             {
                                                loading ?

                                                    <CircularProgress />
                                                    :
                                                        answer !== false ?
                                                    <Alerts success={answer} type={'player'} message={message} action={'updated'}/>
                                                    :
                                                    <div></div>
                                            }
                    </form>
                  )}
                  </Grid>
            <Grid item xs={6}>
              {/* Right side */}
              {nationalityFound.length > 0 && (
                <div>
                  <h4>Nationalities:</h4>
                  {nationalityFound.map((nationality) => (
                    <div style={{ marginTop: 20}} onClick={() => addItemToNationality(nationality.id, nationality.name, nationality.flag)} key={nationality.id}>
                     <img style={{width: 15, height: 10}}  src={nationality.flag} alt={' '} />  {nationality.name}
                    </div>
                  ))}
                </div>
              )}

              {selectedNationality.length > 0 && (
                <div>
                  <h4>New nationalities for the player:</h4>
                  <ul>
                    {selectedNationality.map((nation) => (
                      <li key={nation.id} style={{listStyleType: 'none'}}>
                          <img style={{width: 15, height: 10}} src={nation.flag} alt={' '} />
                          {" "}{nation.name}
                      </li>
                    ))}
                  </ul>
                </div>
              )}

              {positionFound.length > 0 && (
                <div style={{ marginTop: 35 }}>
                  <h4>Positions:</h4>
                  {positionFound.map((position) => (
                    <div style={{ marginTop: 20}} onClick={() => addItemToPosition(position.id, position.name)} key={position.id}>
                      {position.name}
                    </div>
                  ))}
                </div>
              )}

              {selectedPosition.length > 0 && (
                <div>
                  <h4>New positions for the player:</h4>
                  <ul>
                    {selectedPosition.map((position) => (
                      <li key={position.id}>{position.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </Grid>

                </Grid>
          <Grid container spacing={2} style={{marginTop: 15}}>
              <Grid>
                <h3>List of Player Representation (aka Versions of Player)</h3>
                <div>
                    {
                        playerData === null ? " " :

                            playerData.profile.map((profile) => (
                               <Link to={`/reprs/${profile.id}`} >
                                    <p> Representation:  {profile.id} – id. {profile.date_create} -- date of creation  </p>
                               </Link>
                            ))
                    }
                </div>
              </Grid>

          </Grid>
          <HistoryOfChanges entity_type={"player"} entity_id={id}
              ></HistoryOfChanges>
      </div>
  );
}
