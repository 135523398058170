import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TablePagination, CircularProgress,
} from '@mui/material';
import { base_url } from '../../index';
import Alerts from "../Alerts/Alerts";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import save_logs from "../../tools/save_logs";
import TokenChecker from "../Header/TokenChecker";

export default function Squad() {
  const { match_id } = useParams();
  const { squad_id } = useParams();

  const [match, setMatch] = useState(1)
  const [squad, setSquad] = useState(1)
  const [updatePlayer, setUpdatePlayer] = useState(null)
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsTech, setSearchResultsTech] = useState(0);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [page, setPage] = useState(1);
  const [isInitialSearch, setIsInitialSearch] = useState(true);
  const [answer, setAnswer] = useState(false)
  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false);

  const fetchMatch = async () => {
    const response = await fetch(`${base_url}match/${match_id}`)
    const data = await response.json()
    setMatch(data)
  }

  const fetchSquad = async () => {
    const response = await fetch(`${base_url}squad/${squad_id}`)
    const data = await response.json()
    setSquad(data)
  }

  const handleSquadCompare = async () => {
    try {
      setLoading(true)
      const response = await fetch(`${base_url}squad_compare/${squad_id}`)
      const data = await response.json()
      if (data.count !== 0) {
        setSelectedPlayers((prevPlayers) => [...prevPlayers, ...data.players]);
      } else {
        alert('Missing players not found')
      }


    }
    catch (e) {
      console.log(e)
    }
    finally {
      setLoading(false)
    }

  }

  console.log(selectedPlayers)

  const handleSearch = async () => {
      if (!query) return;
      try {
         setLoading(true)
        const response = await fetch(`${base_url}player/?search=${query}&page=${page}`);
        const data = await response.json();
        setSearchResultsTech(data.count);
        setSearchResults(data.results);

        if (isInitialSearch) {
          setIsInitialSearch(false); // Set isInitialSearch to false after first successful search
        }
      }
     catch (e) {
       console.error(e)
     }
     finally {
        setLoading(false)
      }

  };

  const getPlayerIdsArray = () => {
    return selectedPlayers.map((player) => ({ player: player.id }));
  };

  const updateSquad = async () => {
    try {
      const idsArray = getPlayerIdsArray();
        const requestOptions = {
          method: 'PATCH',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            "roster": idsArray
          }),
        };
        const response = await fetch(`${base_url}squad_update/${squad_id}/`, requestOptions);
        const data = await response.json()
        if (data.id) {
                    await setAnswer('success')
                    await setMessage(data)
                    await setSearchResults([])
                    await setSelectedPlayers([])
                    await save_logs('match', match_id, 'squad update', 'a')
                    await fetchSquad()


                } else {
                    await setAnswer('error')
                    await setMessage(data)
                }
    }
    catch (e) {
      console.error(e)
    }
    finally {
      setLoading(false)
    }
  };


  const removePlayerFromSquad = async (player_marker_id) => {
    const confirmed = window.confirm("Are you sure you want to remove this player from the squad?");
    if (confirmed) {
    try {
      const response = await fetch(`${base_url}player_in_squad/${player_marker_id}/`, {
        method: "DELETE"
      });
      const data = await response.json();
      await fetchSquad()
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }

  }

  const handlePlayer = async (player_id) => {
    const response = await fetch(`${base_url}player_reprs/${player_id}/`);
    const data = await response.json()
    setUpdatePlayer(data)

  }
  const handleAddToSquad = (player) => {
    setSelectedPlayers((prevPlayers) => [...prevPlayers, player]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleRemoveFromSquad = (id) => {
    setSelectedPlayers((prevPlayers) => prevPlayers.filter((player) => player.id !== id));
  };


  console.log(selectedPlayers)


  useEffect(() => {
    fetchMatch()
    fetchSquad()
    if (!isInitialSearch) {
      handleSearch(page);
    }
  }, [page, isInitialSearch]);

  return (

      <Container>
        <Button>
          <Link to={`/match/${match_id}`}>
          ⬅️ Back
          </Link>

        </Button>
    <Grid container spacing={2}>


      <Grid item xs={4}>

    <Button onClick={() => handleSquadCompare()}>
      Get players from the past squad
    </Button>
                    <TokenChecker />

        <h3>New players in the Squad of </h3>
        <h3 style={{color: '#4169e1'}}>{squad.team === undefined ? " " : squad.team.name + ' (' + squad.team.id + ')' } </h3>
        <TableContainer component={Paper}>
          {loading ?
              <CircularProgress/>
              :

              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Player Name (Nickname)</TableCell>
                    <TableCell>Remove Player</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedPlayers.map((player) => (
                      <TableRow key={player.id}>
                        <TableCell>{player.id}</TableCell>
                        <TableCell> {player.nickname !== null ? player.nickname : `${player.name} ${player.middle_name ?? ''} ${player.surname ?? ''}`}</TableCell>
                        <Button onClick={() => handleRemoveFromSquad(player.id)}>❌</Button>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
          }
          <Button onClick={() => updateSquad()}>
            Add players to Squad
          </Button>
        </TableContainer>
          {
            loading ? ' ' :
                                answer !== false ?
                                    <Alerts success={answer} type={'squad'} message={message} action={'updated'}/>
                                    :
                                    <div></div>
                            }
      </Grid>
      <Grid item xs={8}>
        <h3>Search Player</h3>
        <TextField
          label="Search Player"
          variant="outlined"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          fullWidth
        />
        <Button onClick={handleSearch}>Find Player</Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Add to Squad</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>Full name</TableCell>
                <TableCell>Nickname</TableCell>
                <TableCell>Nationality</TableCell>
                <TableCell>Flag</TableCell>
                <TableCell>Position(s)</TableCell>
                <TableCell>Birthday</TableCell>
                <TableCell>Height (cm)</TableCell>
                <TableCell>Weight (kg)</TableCell>
                <TableCell>Leg</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {loading ? (
                        <TableRow>
                          <TableCell colSpan={4} align="center">
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      ) : (searchResults.map((player) => (
                <TableRow key={player.id}>
                  <TableCell>
                    <Button onClick={() => handleAddToSquad(player)}>➕</Button>
                  </TableCell>
                  <TableCell>
                    <Link to={`/player/${player.id}`}>
                      {player.id}
                    </Link>
                  </TableCell>
                  <TableCell>{player.name} {player.middle_name ?? ''} {player.surname ?? ''}</TableCell>
                  <TableCell>{player.nickname ?? ''} </TableCell>
                  <TableCell>
                    {player.nationality.map(nation => nation.name + '  ')}
                  </TableCell>
                  <TableCell>
                     {player.nationality.map(nation =>  <img src={nation.flag} alt={nation.short_name} height={20} />)}
                  </TableCell>
                  <TableCell>{player.position.map(pos => pos.short_name + '  ')}</TableCell>
                  <TableCell>{player.birth_date}</TableCell>
                  <TableCell>{player.height}</TableCell>
                  <TableCell>{player.weight}</TableCell>
                  <TableCell>{player.leg_display}</TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={searchResultsTech}
            page={page - 1}
            rowsPerPage={10}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      </Grid>
    </Grid>
        <Grid container spacing={2} style={{marginTop: 20}}>
          <Grid item xs={6}>
              <h1>Current Formation</h1>
              <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Remove from Squad</TableCell>
                    {/*<TableCell>Update Player</TableCell>*/}
                    <TableCell>ID</TableCell>
                    <TableCell>Full name or Nickname</TableCell>
                    <TableCell>Positions</TableCell>
                    <TableCell>Birthday</TableCell>
                    <TableCell>Height (cm)</TableCell>
                    <TableCell>Weight (kg)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>

                  {
                  squad.roster === undefined ? '' : squad.roster.map((player) => (
                    <TableRow key={player.id}>
                      <TableCell>
                        <Button onClick={() => removePlayerFromSquad(player.player_marker_id)}> ❌ </Button>
                      </TableCell>
                      {/* <TableCell>*/}
                      {/*  <Button onClick={() => handlePlayer(player.id)}> 🛠 </Button>*/}
                      {/*</TableCell>*/}
                      <TableCell>
                        <Link to={`/player/${player.id}`}>
                          {player.id}
                        </Link>
                      </TableCell>
                      <TableCell onClick={() => console.log(player.player_marker_id)}>{player.name} {player.middle_name ?? ''} {player.surname ?? ''}</TableCell>
                      <TableCell>{player.position.map(pos => pos.short_name + '  ')}</TableCell>
                      <TableCell>{player.birth_date}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

        </Grid>
      </Container>
  );
}
