import FormControl from "@mui/material/FormControl";
import {Button, CircularProgress, FormLabel, Select, TablePagination, TextField} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {base_url} from "../../index";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import {createHashRouter, json, Link} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Alerts from "../Alerts/Alerts";
import TokenChecker from "../Header/TokenChecker";


export default function Teams() {
    const [params, setParams] = useState()
    const [result, setResult] = useState([])

    const [page, setPage] = useState(1);

    const [searchResults, setSearchResults] = useState([]);
    const [searchResultsTech, setSearchResultsTech] = useState(0);


    const [name, setName] = useState("")
    const [short_name, setShortName] = useState("")
    const [color, setColor] = useState("")
    const [type, setType] = useState()
    const [status, setStatus] = useState()
    const [image, setImage] = useState()

    const [add, setAdd] = useState(false)
    const [answer, setAnswer] = useState(false)
    const [message, setMessage] = useState()

    const [loading, setLoading] = useState(false);


    function onImageChange(e) {
        setImage([...e.target.files])
    }


    async function fetchSearch() {
        if(typeof params !== 'number') {
            const response = await fetch(base_url + 'team/?search=' + params)
            const data = await response.json()
            await setResult(data.results)

        }
    }

    useEffect(() => {
        pageFetchSearch(page)
    }, [page])

    async function pageFetchSearch() {
        if(typeof params !== 'number') {
            try {
                setLoading(true)
                const response = await fetch(`${base_url}team/?search=${params}&page=${page}`)
                const data = await response.json()
                setSearchResultsTech(data.count);
                setSearchResults(data.results);
                console.log(searchResults)
                console.log(searchResultsTech)
            }
            catch (e) {
                console.error(e)
            }
            finally {
                setLoading(false)
            }
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1); // Add 1 to match the current page number for API call
    };




    const addTeam = async () => {
        let formData = new FormData()
        formData.append("name", name)
        formData.append("short_name", short_name)
        formData.append("color", color)
        formData.append("type", type)
        formData.append("status", status)
        formData.append("logo", image[0])

        console.log(image[0])
        try {
            const fetchResponse = await fetch(base_url + 'team/', {
                    method: "POST",
                    body: formData,
            });
            console.log(fetchResponse)
            const data = await fetchResponse.json();
            console.log(data)
            if (data.id) {
                await setAnswer('success')
                await setMessage(data)
            } else {
                await setAnswer('error')
                await setMessage(data)
            }
        }
        catch (e) {
            await setAnswer("error")
        }
    }


    const addLogo = async (team_id) => {
        console.log(team_id)
        try {
            const fetchResponse = await fetch(base_url + 'team/' + team_id + '/', {
                method: "PATCH",
                body: JSON.stringify({
                    "logo": image
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await fetchResponse.json()
            console.log(data)
        }
        catch (e) {
            return e
        }
    }

    return (
        <div>
                        <TokenChecker />

            <FormControl>
                <FormLabel>Enter name or Team</FormLabel>
                    <TextField required
                    onChange={e => setParams(e.target.value)}
                    >
                    </TextField>
                    <Button onClick={() => pageFetchSearch()}>
                        Find Team
                    </Button>
          </FormControl>
            <div>
                {result == false ?
                    <div>
                        <h4>The team was not found? Want to create? </h4>
                        <Button onClick={() => setAdd(true)}>Create Team</Button>
                        <p>

                        </p>
                        {add === false ? " " :
                            <FormControl>

                                <FormLabel>Type Name of Team</FormLabel>
                                <TextField onChange={e => setName(e.target.value)}></TextField>

                                <FormLabel>Type Short name of Team</FormLabel>
                                <TextField onChange={e => setShortName(e.target.value)}></TextField>

                                <FormLabel>Type colorscheme of Team (example: 108, 172, 228;4, 30, 66)</FormLabel>
                                <TextField onChange={e => setColor(e.target.value)}></TextField>

                                <FormLabel>Choose Team Type</FormLabel>
                                <Select value={type} onChange={e => setType(e.target.value)}>
                                    <MenuItem value={1}>Club</MenuItem>
                                    <MenuItem value={2}>Nation Team</MenuItem>
                                </Select>

                                <FormLabel>Choose Team Status</FormLabel>
                                <Select value={status} onChange={e => setStatus(e.target.value)}>
                                    <MenuItem value={1}>Professional</MenuItem>
                                    <MenuItem value={2}>Semi-professional</MenuItem>
                                    <MenuItem value={3}>Amateur</MenuItem>
                                </Select>

                                <p></p>

                                <Button variant="contained" component="label">
                                    Upload Logo of Team <input accept="image/*" multiple type="file"
                                                               onChange={onImageChange}/>
                                </Button>


                                <div>
                                    {image === undefined ? " "
                                        :
                                        <p> The command logo is added </p>
                                    }
                                </div>
                                <p>

                                </p>

                                <Button onClick={() => addTeam()}>
                                    Add Team
                                </Button>

                                {
                                    answer !== false ?
                                        <Alerts success={answer} type={'team'} message={message} action={'added'}/>
                                        :
                                        <div></div>
                                }

                            </FormControl>
                        }
                    </div>
                    :
                    <div>
                        <p></p>
                    </div>
                }
                {
                    loading === true ? <CircularProgress /> :  searchResults == false ? " "
                    :
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Team ID</TableCell>
                            <TableCell>Team Logo</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="right">Short Name</TableCell>
                            <TableCell align="right">Team Status</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                searchResults.map((team) => (
                                    <TableRow key={team.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                        <TableCell align="left">{team.id}</TableCell>
                                        <TableCell align="left"><image src={team.logo}/></TableCell>
                                          <TableCell component="th" align="left" scope="row">
                                              <Link to={`/team/${team.id}`}> {team.name} </Link>
                                          </TableCell>
                                          <TableCell align="right">{team.short_name}</TableCell>
                                          <TableCell align="right">{team.team_status_display}</TableCell>
                                    </TableRow>
                                    )
                                )
                            }

                        </TableBody>
                         <TablePagination
                            component="div"
                            count={searchResultsTech}
                            page={page - 1}
                            rowsPerPage={10}
                            onPageChange={handleChangePage}
                          />
                    </Table>
                }
            </div>
        </div>
    )
}