import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Box, Snackbar } from '@mui/material';
import { base_url, base_url_long } from "../../index";
import { useParams } from "react-router-dom";
import Alert from "@mui/material/Alert";
import ReactPlayer from 'react-player';

const VideoMarking = () => {
  const [videoData, setVideoData] = useState(null);
  const [markersData, setMarkersData] = useState(null);
  const [formValues, setFormValues] = useState({
    firstTime: '',
    secondTime: '',
    firstExtraTime: '',
    secondExtraTime: ''
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const playerRef = useRef(null);
  const [seekTimeout, setSeekTimeout] = useState(null);
  const [allMarkersHaveTimestamps, setAllMarkersHaveTimestamps] = useState(false);


  let { id } = useParams();

  const fetchVideo = async () => {
    const response = await fetch(`${base_url}match/${id}/`);
    const data = await response.json();
    await setVideoData(data.videos[0]);
  };

  const fetchMarkers = async () => {
    try {
      const response = await fetch(`${base_url_long}registrator/match_markers_fast/${id}`);
      const data = await response.json();
      const filteredMarkers = data.markers.filter(marker =>
        [1, 75, 81, 85].includes(marker.action.id)
      );
      await setMarkersData(filteredMarkers);

      const allHaveTimestamps = filteredMarkers.every(marker => marker.timestamp !== undefined && marker.timestamp !== null);
      setAllMarkersHaveTimestamps(allHaveTimestamps);
    } catch (error) {
      console.error('Error fetching markers:', error);
    }
  };

  useEffect(() => {
    fetchVideo();
    fetchMarkers();
  }, []);

  const handleKeyDown = (event) => {
    if (!playerRef.current) return;

    if (event.key === 'ArrowRight' || event.key === 'ArrowLeft') {
      event.preventDefault();

      const currentTime = playerRef.current.getCurrentTime();
      if (event.key === 'ArrowRight') {
        playerRef.current.seekTo(currentTime + 3, 'seconds');
      } else if (event.key === 'ArrowLeft') {
        playerRef.current.seekTo(Math.max(currentTime - 3, 0), 'seconds');
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const convertToSeconds = (time) => {
    if (!time) return 0;

    const parts = time.split(':').reverse();
    let seconds = 0;

    if (parts[0]) seconds += parseInt(parts[0]); // Секунды
    if (parts[1]) seconds += parseInt(parts[1]) * 60; // Минуты
    if (parts[2]) seconds += parseInt(parts[2]) * 3600; // Часы

    return seconds;
  };

  const seekToTime = (seconds) => {
    if (playerRef.current) {
      playerRef.current.seekTo(seconds, 'seconds');
      console.log(`Seeking to ${seconds} seconds`);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    const timeInSeconds = isNaN(value) ? convertToSeconds(value) : parseFloat(value);
    if (seekTimeout) {
      clearTimeout(seekTimeout);
    }

    const newTimeout = setTimeout(() => {
      let seekTime = timeInSeconds;

      if (name === 'firstTime') {
        seekTime += 45 * 60;
      } else if (name === 'secondTime') {
        seekTime += 90 * 60;
      } else if (name === 'firstExtraTime') {
        seekTime += 105 * 60;
      } else if (name === 'secondExtraTime') {
        seekTime += 120 * 60;
      }

      seekToTime(seekTime);
    }, 3000);

    setSeekTimeout(newTimeout);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const markerUpdates = [
      { actionId: 1, time: convertToSeconds(formValues.firstTime) },
      { actionId: 75, time: convertToSeconds(formValues.secondTime) },
      { actionId: 81, time: convertToSeconds(formValues.firstExtraTime) },
      { actionId: 85, time: convertToSeconds(formValues.secondExtraTime) }
    ];

    const patchRequests = markersData.map(marker => {
      const update = markerUpdates.find(update => update.actionId === marker.action.id);

      if (update && update.time) {
        return fetch(`${base_url_long}registrator/marker/${marker.id}/`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ timing: update.time })
        });
      }

      return null;
    }).filter(Boolean);

    try {
      await Promise.all(patchRequests);
      setSuccessMessage('Markers updated successfully');
    } catch (error) {
      setErrorMessage('Error updating markers');
    }
  };

  const handleClose = () => {
    setSuccessMessage('');
    setErrorMessage('');
  };

  return (
    <>
      <Box style={{ marginTop: 25, width: 1066, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
       {videoData && markersData ? (
           <>
             <Snackbar open={allMarkersHaveTimestamps} autoHideDuration={6000} onClose={() => setAllMarkersHaveTimestamps(false)}>
        <Alert severity="info" sx={{ width: '100%' }}>
          All markers have timestamps!
        </Alert>
      </Snackbar>
        <ReactPlayer
          ref={playerRef}
          url={videoData.link}
          controls
          width="60%"
          height="auto"
        />
        </>
      ) : (
        <p>Loading video...</p>
      )}

        <form onSubmit={handleSubmit} style={{ width: '35%' }}>
          <TextField
            label="First Time"
            name="firstTime"
            value={formValues.firstTime}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Second Time"
            name="secondTime"
            value={formValues.secondTime}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="First Extra Time"
            name="firstExtraTime"
            value={formValues.firstExtraTime}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Second Extra Time"
            name="secondExtraTime"
            value={formValues.secondExtraTime}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Submit
          </Button>
        </form>
      </Box>

      <Snackbar open={!!successMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar open={!!errorMessage} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default VideoMarking;
